<template>
	<b-card-group>
		<b-card>
			<h4
				v-if="count > 1"
				class="mb-4">
				{{ `${translate('co_applicants')} (${count})` }}
			</h4>
			<h4
				v-else
				class="mb-4">
				{{ translate('co_applicants') }}
			</h4>
			<is-loading
				:loading="loading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')" />
			<div class="table-responsive">
				<div
					v-for="(data, key) in coApplicantsData"
					:key="key">
					<table
						v-show="!loading && Object.values(data.attributes).length > 0"
						class="table border border-top-0">
						<tbody>
							<general-setting-field
								v-for="item in Object.keys(data.attributes)"
								:key="item"
								:key-value="item"
								:field-information="{editable: false, value: data.attributes[item]}"
								:field-name="checkFieldName(item)"
								:loading="loading" />
						</tbody>
					</table>
					<br>
				</div>
			</div>
		</b-card>
	</b-card-group>
</template>
<script>

import GeneralSettingField from '@/views/Users/components/GeneralSettingsField';
import CoApplicants from '@/util/CoApplicants';
import {
	Profile as ProfileTranslations, Validations, Users, Alerts,
} from '@/translations';
import { distributor } from '@/settings/Roles';

export default {
	name: 'CoApplicants',
	messages: [ProfileTranslations, Validations, Users, Alerts],
	components: { GeneralSettingField },
	data() {
		return {
			alert: new this.$Alert(),
			distributor,
			coApplicants: new CoApplicants(),
		};
	},
	computed: {
		coApplicantsData() {
			try {
				const { data } = this.coApplicants.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		count() {
			try {
				const { total } = this.coApplicants.data.response.data.meta;
				return total;
			} catch (error) {
				return [];
			}
		},
		loading() {
			try {
				return this.coApplicants.data.loading;
			} catch (error) {
				return false;
			}
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() { this.coApplicants.getCoApplicants(this.$user.details().id); },
		checkFieldName(item) {
			if (typeof this.translate(item) !== 'undefined') { return this.translate(item); }
			return '';
		},
	},
};
</script>
